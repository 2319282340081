<template>
  <div class="verification">
    <div class="title">核销记录</div>
    <ym-table-list :data="data"
                   :columns="columns"
                   operatorWidth="100px"
                   :pageLayout="pageLayout"
                   :total="total"
                   :pageNumber="page"
                   @handlePageJump="handlePageJump">

      <template #topControl>
        <div class="top-control clearfix">
          <!-- 选择的状态条件标签 -->
          <!-- 顶部右侧搜索，刷新和新增 -->
          <div class="top-control-refresh">
            <el-button icon="el-icon-refresh"
                       @click="handleRefresh">
            </el-button>
          </div>
          <div class="top-control-search">
            <el-input v-model="search"
                      placeholder="搜索兑换码"
                      prefix-icon="el-icon-search"
                      clearable
                      @clear="handleRefresh"
                      @keyup.enter.native="handleSearch">
            </el-input>
          </div>
        </div>
      </template>

      <template #exchangeTime="{row}">
        <template v-if="row.exchangeTime">{{row.exchangeTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</template>
        <template v-else>-</template>
      </template>

      <template #operatorCol>
        <!-- <el-button class="operator-btn ym-icon-liulan"
                   @click.stop="showDetail(row)">详情</el-button> -->
      </template>

    </ym-table-list>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      // 列表数据项
      data: [],
      // 列表表头配置项
      columns: [{
        label: '兑换码',
        prop: 'code',
        align: 'center'
      },
      {
        label: '票券名称',
        prop: 'ticketName',
        align: 'center'
      },
      {
        label: '手机号码',
        prop: 'phone',
        align: 'center'
      },
      {
        label: '核销时间',
        prop: 'exchangeTime',
        align: 'center',
        enableColSlot: true,
        width: '200px'
      }
      ],
      pageLayout: 'prev, pager, next, jumper',
      total: 10, // 数据总数
      page: 1,
      size: 10,
      search: '' // 搜索内容
    }
  },
  watch: {

  },
  mounted() {
    this.getList()
  },
  methods: {
    // 对搜索框及状态选择标签进行搜索
    handleSearch() {
      this.page = 1
      this.getList()
    },
    // 清空搜索条件(包括状态选择和搜索文本)
    handleRefresh() {
      this.search = ''
      this.handleSearch()
    },
    // 处理页码跳转
    handlePageJump(page, pageSize) {
      this.page = page
      this.getList()
    },
    getList() {
      this.$http.ticket.getVerificationList({
        size: this.size,
        page: this.page,
        keywords: this.search
      }).then(res => {
        if (res.data.code === 0) {
          this.data = res.data.data.records
          this.total = res.data.data.total
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    }
  }

}
</script>

<style lang="scss">
.verification {
  .title {
    font-size: 20px;
    font-weight: 600;
    color: $--color-text-primary;
    margin-bottom: 30px;
  }
  .top-control {
    margin-top: 16px;
    margin-bottom: 16px;
    .dropdownlist-tag {
      float: left;
      margin-left: 32px;
      .el-tag {
        position: relative;
        width: 110px;
        height: 40px;
        font-size: 14px;
        line-height: 41px;
        background-color: $--color-primary-light-9;
        border: none;
        border-radius: 6px;
        color: $--color-text-primary;
        padding-left: 20px;
        padding-right: 11px;
        cursor: default;
        .el-icon-close {
          position: absolute;
          right: 11px;
          top: 50%;
          margin-top: -8px;
          color: $--color-text-primary;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    &-search {
      float: right;
      width: 240px;
      margin-right: 24px;
      .el-input {
        border-radius: 6px;
      }
    }
    &-refresh {
      float: right;
      margin-right: 24px;
      .el-button {
        padding: 8px 11px;
        .el-icon-refresh::before {
          font-size: 20px;
        }
      }
    }
    &-add {
      float: right;
      margin-right: 30px;
      font-size: $--font-size-medium;
      font-weight: $--font-weight-primary;
    }
  }
  .dropdownlist {
    padding-top: 19px;
    padding-bottom: 19px;
    border-top: 1px solid #e5e8f3;
    .el-button {
      width: 90px;
      height: 41px;
      margin-left: 32px;
    }
  }
}
.tag {
  margin-right: 10px;
}
</style>
